const getTokenLogoURL = (address: string) => {
  console.log(address);

  if(address === "0x6A26D0eA90B9169a3b3e5696e4EF9f0596beC0f3" || address === "0x7aA99296F0468D92f6dBdD0Fcfa5d166B4fB83f9")
    return `images/tokens/${address}.png`;

  return `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${address}/logo.png`
}

export default getTokenLogoURL
